// 获取权限
import request from '@/utils/request'

// 获取登录地址
export function getSsoAuthUrl(query) {
  return request({
    url: `/auth/setting/sso/getSsoAuthUrl?clientLoginUrl=${query.clientLoginUrl}`,
    method: 'get'
  })
}

// ticket换token
export function doLoginByTicket(query) {
  return request({
    url: `/auth/setting/sso/doLoginByTicket?ticket=${query.ticket}`,
    method: 'get'
  })
}

// 登出 
export function autologout() {
  return request({
    url: '/auth/setting/sso/logout',
    method: 'get',
  })
}
