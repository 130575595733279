import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/loadingPage'
  },
  {
    path: '/loadingPage',
    name: 'loadingPage',
    component: () => import('@/views/loadingPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue'),
    children: [
      {
        path: '/set/rule',
        component: () => import('@/views/system/set/index.vue'),
        name: "rule",
        meta: {
          title: "权限",
          icon: "index",
          affix: true,
          noCache: true,
        },
      }, {
        path: '/set/library',
        component: () => import('@/views/system/libraryMaintenance.vue'),
        name: "library",
        meta: {
          title: "库列表",
          icon: "index",
          affix: true,
          noCache: true,
        },
      },
      {
        path: '/set/userMange',
        component: () => import('@/views/system/userMange.vue'),
        name: "userMange",
        meta: {
          title: "用户组权限管理",
          icon: "index",
          affix: true,
          noCache: true,
        },
      },
      {
        path: '/set/ysMangeSet',
        component: () => import('@/views/system/ysMangeSet.vue'),
        name: "ysMangeSet",
        meta: {
          title: "人员权限管理",
          icon: "index",
          affix: true,
          noCache: true,
        },
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
