import axios from 'axios'
import { ElNotification } from 'element-plus'
import { getToken } from '@/utils/user'
import Config from '@/utils/settings'
import router from "@/router/index";
import { handleLogout } from '@/utils/bylog'

// 创建axios实例
let str = window.location.href
let baseURL = str.indexOf('auth.ysbuy.com') != -1 ? 'https://auth.ysbuy.com/api' : str.indexOf('autht.ysbuy.com') != -1 ? 'https://autht.ysbuy.com/api' : "https://autht.ysbuy.com/api"
// let baseURL = 'http://192.168.0.95:6401'
const service = axios.create({
  baseURL: baseURL,
  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    let code = response.data.code
    let message = response.data.msg || response.data.messages || response.data.message || response.data.info || '接口请求失败'
    if (code == 200) {
      return response.data
    } else {
      if (code == '901' || code == '401') {
        if (!response.config.url.includes('getSsoAuthUrl') && !response.config.url.includes('setting/view/url')) {
          ElNotification.error('您的登录已失效，请重新登录')
        }
        handleLogout()
        return Promise.reject('error')
      } else if (code == 999) {
        ElNotification.error(message)
        router.replace('/404')
      } else {
        ElNotification.error(message)
        return Promise.reject('error')
      }
    }
  },
  error => {
    let status = error.response.status
    let message = error.response.data.msg || error.response.data.messages || error.response.data.message || error.response.data.info || '接口请求失败'
    if (status == '901' || status == '401') {
      if (!error.response.config.url.includes('getSsoAuthUrl') && !error.response.config.url.includes('setting/view/url')) {
        ElNotification.error('您的登录已失效，请重新登录')
      }
      handleLogout()
    } else if (status == 999) {
      ElNotification.error(message)
      router.replace('/404')
    } else {
      ElNotification.error(message)
    }
    return Promise.reject(error)
  }
)
export default service