<template>
  <div class="home">
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup >
</script>

<style lang="less">
.home {
  height: 100%;
  .menuTop {
    height: 100%;
  }
  .menuLeft {
    height: 100%;
  }
  .el-menu {
    border: none;
    height: 50px !important;
    width: 100% !important;
  }
  .el-header {
    padding: 0 !important;
    height: 50px;
  }
  .el-container {
    height: 100%;
  }
  .el-main {
    padding: 0 !important;
  }
  /* 侧边 */
  .el-aside {
    height: 100%;
    border-right: 5px #ededed solid;
    width: auto !important;
    min-width: 200px;
  }
}
.lastCss {
  color: #1890ff;
}
.urlCss {
  cursor: pointer;
}
.urlCss:hover {
  color: #1890ff;
}
</style>