import router from "./index";
import store from '../store/index.js'
import { getToken } from "@/utils/user";
import { handleLogout } from '@/utils/bylog'
//  在路由之前监听获取用户信息
let userInfo = {}
store.watch((state, getters) => {
  userInfo = state.user.user
})
document.title = 'YEASEN菜单系统'
// 监听路由
router.beforeEach((to, from, next) => {
  if (getToken()) {
    let { path, params, query } = to
    let obj = { path, params, query, title: to.meta.title }
    let newobj = { 'routerInfo': obj, type: 2 }
    // 需要三次
    // 保留浏览历史记录
    if (store.state.user.roluteHistory.length) {
      newobj.type = 3
      store.commit('saveRouter', newobj)
    }
    else {
      newobj.type = 3
      store.commit('saveRouter', newobj)
    }
    // 浏览记录最多保留十五个
    if (store.state.user.roluteHistory.length > 15) {
      newobj.type = 2
      store.commit('saveRouter', newobj)
    }
    judegTopMenu(to, store)
    next()
  } else {
    if (to.path.includes('loadingPage') || to.path.includes('404')) {
      next()
    } else {
      handleLogout()
    }
  }
})

function judegTopMenu(to, store) {
  let { path } = to
  let patharr = path.split("/")
  let topMenuList = store.state.user.topMenuList
  let arr = []
  let status = false
  topMenuList.forEach((ele, index) => {
    if (ele.pageUrl == patharr[1]) {
      store.commit('settopActive', ele.id)  //获取顶部菜单默认选择 
      if (store.state.user.topActive == index) {
        status = true
      }
      arr = ele.compos
    }
  });
  arr.forEach(element => {
    if (element.pageUrl == to.path) {
      store.commit('setdefaultActive', element.id) //获取当前系统的一级菜单
    }
    let child = element.compos
    child && child.forEach(ele => {
      if (ele.pageUrl == to.path) {
        store.commit('setdefaultActive', ele.id)
      }
    });
  });

}