import { removeToken } from '@/utils/user';
import { getSsoAuthUrl } from "@/api/login";

// 登录状态失效
export const handleLogout = () => {
  console.log(window.location)
  let pathname = window.location.pathname
  const back = pathname.includes('loadingPage') || pathname == '/' ? '' : window.location.pathname
  const clientLoginUrl = `${window.location.origin}/loadingPage&back=${back}`
  removeToken()
  getSsoAuthUrl({ clientLoginUrl }).then(res => {
    const { data, code } = res
    if (code == 200) {
      setTimeout(() => {
        window.location.href = data
      }, 1500)
    }
  })
}


