// 用户信息模块
const user = {
  state: {
    systemplist: [],
    topMenuList:  [
      {
        id: '11-22',
        pid: '1',
        name: '权限设置',
        url: '33',
        pageUrl: 'set',
        deep: 1,
        icon: '-',
        order: 1,
        compos: [
          {
            id: '331-22',
            pid: '1',
            name: '权限',
            url: '33',
            pageUrl: '/set/rule',
            deep: 1,
            icon: '-',
            order: 1,
          },
          {
            id: '3231-22',
            pid: '1',
            name: '库列表',
            url: '33',
            pageUrl: '/set/library',
            deep: 1,
            icon: '-',
            order: 1,
          },
        ],
      },
    ],
    leftMenu: [
      {
        id: '331-22',
        pid: '1',
        name: '权限',
        url: '33',
        pageUrl: '/set/rule',
        deep: 1,
        icon: '-',
        order: 1,
      },
      {
        id: '3231-22',
        pid: '1',
        name: '库列表',
        url: '33',
        pageUrl: '/set/library',
        deep: 1,
        icon: '-',
        order: 1,
      },
      {
        id: '3231-212',
        pid: '1',
        name: '用户组权限配置',
        url: '33',
        pageUrl: '/set/userMange',
        deep: 1,
        icon: '-',
        order: 1,
      },
      {
        id: '3231-2112',
        pid: '1',
        name: '人员权限设置',
        url: '33',
        pageUrl: '/set/ysMangeSet',
        deep: 1,
        icon: '-',
        order: 1,
      },
    ],
    systemName: '',
    defaultActive: '',
    topActive:"11-22",
    roluteHistory: [],
    ListMenu:[],
    isCollapse:true
  },

  mutations: {
    // 获取系统菜单
    getMenuList: (state, systemplist) => {
      state.systemplist = systemplist
    },
    // 获取顶部菜单
    gettopMenuList: (state, topMenuList) => {
      state.topMenuList = topMenuList
    },
    // 获取侧边菜单
    getleftMenu: (state, leftMenu) => {
      state.leftMenu = leftMenu
    },
    // 获取系统名称
    getsystemName: (state, systemName) => {
      state.systemName = systemName
    },
    // 设置默认项
    setdefaultActive: (state, defaultActive) => {
      state.defaultActive = defaultActive
    },
     // 设置顶部默认项
     settopActive: (state, topActive) => {
      state.topActive = topActive
    },
    // 记录用户浏览页面
    saveRouter: (state, obj) => {
      // type 1 清空数据 2数据超过15 删除第一项 3 添加数据
      let arr = state.roluteHistory;
      arr.forEach(element => {
        element['activeIndex'] = -1
      });
      obj.routerInfo['activeIndex'] = -1

      let result = arr.findIndex(item => {

        return item.title == obj.routerInfo.title
      })

      if (result != -1) {
        arr[result]['activeIndex'] = result
        return
      } else {
        obj.routerInfo['activeIndex'] = 1
      }
      switch (obj.type) {
        case 1:
          state.roluteHistory = obj.routerInfo || []
          break;
        case 2:
          state.roluteHistory.shift(obj.routerInfo)
          break;
        case 3:

          state.roluteHistory.push(obj.routerInfo)
          break;

      }
    },
    isCollapse:(state,status)=>{
      state.isCollapse = status
    }
    
  },

  actions: {
    changeMenuList({commit}, data) {
      commit('getMenuList', data)
    }
  }

}

export default user